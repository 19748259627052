.edu-page {
  display: flex;
  flex-direction: column;
}
.edu-content {
  display: flex;
  flex-direction: column;
}

.edu-guide-link {
  width: max-content;
  align-self: flex-end;
  padding: 0.5em;
  text-decoration: none;
}

.edu-intro-video {
  align-self: center;
  max-width: 100%;
  height: 200px;
}
.edu-login-button {
  width: max-content;
  max-width: 100%;
  align-self: center;
  margin-top: 2em;
}
.edu-level-picker {
  display: flex;
  flex-direction: column;
}
.school-year-range-choices {
  display: flex;
}
.school-year-range-choices button:disabled {
  color: white;
}
.platform-choices {
  display: flex;
  flex-direction: column;
}
.platform-choices p {
  display: flex;
  align-items: center;
  margin: 0;
}
.platform-choices p img {
  width: 1.5em;
  margin-right: 0.25em;
}
.platform-group {
  display: flex;
  flex-wrap: wrap;
  padding-left: 1.5em;
}
.edu-platform-button {
  width: 6em;
  height: 6em;
  padding: 0;
  background-color: white;
  border: 1px gray solid;
  margin: 0.25em;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.edu-platform-button img {
  max-width: 40%;
}
.edu-currently-chosen {
  border: 2px solid rgb(85, 85, 85);
  background-color: rgb(190, 220, 230);
  margin: 0.25em;
  color: rgb(85, 85, 85);
}
.not-chosen {
  border: 1px solid rgb(85, 85, 85);
  background-color: white;
  color: rgb(85, 85, 85);
  margin: 0.25em;
}
.edu-start-button {
  align-self: flex-end;
  width: 15em;
  height: 4em;
}
.progress-delete-button {
  background: none;
  color: rgb(85, 85, 85);
  align-self: flex-start;
  font-size: small;
  padding: 0.2em;
}
.progress-delete-button:hover {
  font-weight: bold;
}
.demo {
  background-color: greenyellow;
  color: black;
}
